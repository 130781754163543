.zoomed {
	transition: transform 0.3s ease-in-out;
	transform: scale(1.8);
	cursor: zoom-out;
}

.zoom-image {
	/* transform: translate(-50%, 50%); */
	transition: transform 0.3s ease-in-out;
	cursor: zoom-in;
}
